import url from 'url';
import Link from 'next/link';
import {useRouter} from 'next/router';

const NextStepsNavMobile = ({items = []}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    // Map through items to create modified items with updated `copy` text
    const modifiedItems = items.map((item, index) => {
        return {
            ...item,
            copy: item.copy,

            Icon: (props) =>
                item.Icon({
                    fill: index === 0 ? 'var(--interactive-300)' : 'var(--primary)',
                    width: index === 0 ? '20' : props.width,
                    height: index === 0 ? '20' : props.height,
                    ...props,
                }),
        };
    });

    return (
        <div className="next-steps-nav__mobile">
            {Array.isArray(modifiedItems) &&
                modifiedItems.map((option, index) => {
                    const {onClick, href, Icon, copy} = option;
                    if (href === pathname) return null;
                    return (
                        <div key={index} className="next-steps-nav__mobile--option">
                            {onClick && (
                                <div onClick={onClick} className="next-steps__item">
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        style={{
                                            color:
                                                index === 0
                                                    ? 'var(--interactive-300)'
                                                    : 'inherit',
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: copy,
                                        }}
                                    />
                                </div>
                            )}
                            {href && (
                                <Link
                                    href={href}
                                    title={copy}
                                    style={{
                                        color: 'var(--primary)',
                                        textDecoration: 'none',
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                    }}>
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>{' '}
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        style={{
                                            color:
                                                index === 0
                                                    ? 'var(--interactive-300)'
                                                    : 'inherit',
                                        }}
                                        dangerouslySetInnerHTML={{__html: copy}}
                                    />
                                </Link>
                            )}
                        </div>
                    );
                })}
            <style jsx>
                {`
                    .next-steps-nav__mobile {
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 2px;
                        border-bottom: 1px solid var(--gray-200);
                    }
                    .next-steps-nav__mobile--option {
                        text-align: center;
                        padding: 8px;
                        cursor: pointer;
                        height: 76px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 4px;
                    }
                    .next-steps-nav__mobile--icon {
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin: 0 auto 8px auto;
                        fill: var(--interactive-300);
                    }
                    .next-steps-nav__mobile--option:first-child {
                        background: var(--interactive-100);
                    }
                    .next-steps-nav__mobile--option > a,
                    .next-steps-nav__mobile--option > .next-steps__item {
                        color: var(--interactive-300);
                        text-decoration: none;
                        max-width: 100%;
                    }
                    .next-steps__item,
                    .next-steps-nav__mobile--copy {
                        font-size: 12px;
                        line-height: 14px;
                        margin-top: 4px;
                    }
                    .next-steps-nav__mobile--option:nth-child(2) {
                        border-left: 1px solid var(--gray-200);
                        border-right: 1px solid var(--gray-200);
                    }
                `}
            </style>
        </div>
    );
};
export default NextStepsNavMobile;
