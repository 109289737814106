import {useContext, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import {getSegmentNumber, getSlug, getTags, isSpanishPage} from '../../../lib/utils';
import {navItems} from './navItems';
import AppContext from '../../AppContext';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import HiddenOnMobile from 'aac-components/components/HiddenOnMobile';
import NextStepsNavMobile from './NextStepsNavMobile';
import NextStepsNavDesktop from './NextStepsNavDesktop';

/* ABTasty Campaign Imports */
import ABTest from '../../ABTest';
import Variation1 from '../../AAC2-5926/Variation1';
import Variation2 from '../../AAC2-5926/Variation2';
import Variation3 from '../../AAC2-5926/Variation3';
import Variation4 from '../../AAC2-5926/Variation4';

const NextStepsNav = () => {
    const {asPath} = useRouter();
    const slug = getSlug(asPath);

    const spanishPage = isSpanishPage(slug);

    const {isMobile, pageProps} = useContext(AppContext);

    const [items, setItems] = useState(navItems?.default);
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        const tags = getTags();
        const segmentNumber = getSegmentNumber();
        const segmentName = pageProps?.data?.acf?.content_segment;

        const isOutpatient =
            asPath?.includes('outpatient') || segmentName === 'info-drug-nodetox';

        if (tags?.includes('veterans')) {
            setItems(navItems?.veterans);
            return;
        } else if (spanishPage) {
            setItems(navItems?.spanish);
            return;
        } else if (isOutpatient) {
            setItems(navItems?.outpatient);
            return;
        } else if (segmentNumber <= 2) {
            setItems(navItems?.lowSegment);
            return;
        } else {
            setItems(navItems?.default);
        }
    }, [asPath]);

    useEffect(() => {
        setIsClient(true);
    }, []);

    if (!isClient) return null;

    return (
        <>
            {isMobile ? (
                <HiddenOnDesktop>
                    {/* <NextStepsNavMobile items={items} /> */}
                    <ABTest
                        id="AAC2-5926"
                        Control={() => <NextStepsNavMobile items={items} />}
                        variations={[
                            () => <Variation1 items={items} />,
                            () => <Variation2 items={items} />,
                            () => <Variation3 items={items} />,
                            () => <Variation4 items={items} />,
                        ]}
                    />
                </HiddenOnDesktop>
            ) : (
                <HiddenOnMobile>
                    <NextStepsNavDesktop items={items} />
                </HiddenOnMobile>
            )}
        </>
    );
};
export default NextStepsNav;
