import url from 'url';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

const NextStepsNavMobile = ({items = []}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    const [isOpen, setIsOpen] = useState(false);

    /* used to handle the translateY of the search drawer
     * and the open close of the search container for this
     * particular variant
     */
    useEffect(() => {
        const handleClick = (event) => {
            const clickedElement = event.target;

            // Check if the clicked element is or is within the '.nav-triggers__icon--search'
            const exampleClassElement = clickedElement.closest(
                '.nav-triggers__icon--search',
            );

            // if the search drawer is open; if they variant-1 display is blocked then add translatey(93%)
            // if the search drawer is closed; if they variant-1 display is blocked then add translatey(-100%)
            if (exampleClassElement) {
                setTimeout(() => {
                    setIsOpen((prevIsOpen) => {
                        const newIsOpen = !prevIsOpen;

                        let searchDrawer;

                        if (newIsOpen) {
                            searchDrawer = document.querySelector(
                                '.nav-triggers__search.show',
                            );
                        } else {
                            searchDrawer = document.querySelector(
                                '.nav-triggers__search',
                            );
                        }

                        if (searchDrawer) {
                            const elementToCheck = document.querySelector(
                                '#abtest__AAC2-5926 .variant.test__variant-1',
                            );

                            if (elementToCheck) {
                                const computedStyles =
                                    window.getComputedStyle(elementToCheck);

                                if (computedStyles.display === 'block') {
                                    searchDrawer.style.transform = newIsOpen
                                        ? 'translateY(93%)'
                                        : 'translateY(-100%)';
                                }
                            }
                        }

                        return newIsOpen;
                    });
                }, 500); // Add a delay
            }
        };

        // Attach the event listener to the document
        document.addEventListener('click', handleClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []); // Empty dependency array to run once on mount and clean up on unmount

    return (
        <div className="next-steps-nav__mobile">
            {Array.isArray(items) &&
                items.map((option, index) => {
                    const {onClick, href, Icon, copy} = option;
                    if (href === pathname) return null;
                    return (
                        <div key={index} className="next-steps-nav__mobile--option">
                            {onClick && (
                                <div onClick={onClick} className="next-steps__item">
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        dangerouslySetInnerHTML={{
                                            __html: copy,
                                        }}
                                    />
                                </div>
                            )}
                            {href && (
                                <Link
                                    href={href}
                                    title={copy}
                                    style={{
                                        color: 'var(--primary)',
                                        textDecoration: 'none',
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                    }}>
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>{' '}
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        dangerouslySetInnerHTML={{__html: copy}}
                                    />
                                </Link>
                            )}
                        </div>
                    );
                })}
            <style jsx>
                {`
                    .next-steps-nav__mobile {
                        background: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 2px;
                        border-bottom: 4px solid var(--gray-200);
                        border-top: 4px solid var(--gray-200);
                    }
                    .next-steps-nav__mobile--option {
                        text-align: center;
                        padding: 8px;
                        cursor: pointer;
                        height: 76px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 4px;
                    }
                    .next-steps-nav__mobile--icon {
                        display: block;
                        width: 20px;
                        height: 20px;
                        margin: 0 auto 8px auto;
                    }
                    .next-steps-nav__mobile--option > a,
                    .next-steps-nav__mobile--option > .next-steps__item {
                        color: var(--primary);
                        text-decoration: none;
                        max-width: 100%;
                    }
                    .next-steps__item,
                    .next-steps-nav__mobile--copy {
                        font-size: 12px;
                        line-height: 14px;
                        margin-top: 4px;
                    }
                    .next-steps-nav__mobile--option:nth-child(2) {
                        border-left: 4px solid var(--gray-200);
                        border-right: 4px solid var(--gray-200);
                    }
                `}
            </style>
        </div>
    );
};
export default NextStepsNavMobile;
