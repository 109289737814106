import url from 'url';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';

const NextStepsNavMobile = ({items = []}) => {
    const {asPath} = useRouter();
    const pathname = url.parse(asPath)?.pathname;

    const [isOpen, setIsOpen] = useState(false);

    /* used to handle the translateY of the search drawer
     * and the open close of the search container for this
     * particular variant
     */
    useEffect(() => {
        const handleClick = (event) => {
            const clickedElement = event.target;

            // Check if the clicked element is or is within the '.nav-triggers__icon--search'
            const exampleClassElement = clickedElement.closest(
                '.nav-triggers__icon--search',
            );

            // if the search drawer is open; if they variant-2 display is blocked then add translatey(46%)
            // if the search drawer is closed; if they variant-2 display is blocked then add translatey(-100%)
            if (exampleClassElement) {
                setTimeout(() => {
                    setIsOpen((prevIsOpen) => {
                        const newIsOpen = !prevIsOpen;

                        let searchDrawer;

                        if (newIsOpen) {
                            searchDrawer = document.querySelector(
                                '.nav-triggers__search.show',
                            );
                        } else {
                            searchDrawer = document.querySelector(
                                '.nav-triggers__search',
                            );
                        }

                        if (searchDrawer) {
                            const elementToCheck = document.querySelector(
                                '#abtest__AAC2-5926 .variant.test__variant-2',
                            );

                            if (elementToCheck) {
                                const computedStyles =
                                    window.getComputedStyle(elementToCheck);

                                if (computedStyles.display === 'block') {
                                    searchDrawer.style.transform = newIsOpen
                                        ? 'translateY(46%)'
                                        : 'translateY(-100%)';
                                }
                            }
                        }

                        return newIsOpen;
                    });
                }, 500); // Add a delay
            }
        };

        // Attach the event listener to the document
        document.addEventListener('click', handleClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []); // Dependency array to ensure effect only runs once

    /* changes minHeight and paddingTop to account for changes in mobile nav */
    setTimeout(() => {
        const navContainer = document.querySelector('.next-steps-nav__container');
        const pageContainer = document.querySelector('.page-content');

        const elementToCheck = document.querySelector(
            '#abtest__AAC2-5926 .variant.test__variant-2',
        );

        //sets min-height to 0 and padding-top to 137px to account for changes with mobile nav
        if (elementToCheck) {
            const computedStyles = window.getComputedStyle(elementToCheck);
            if (computedStyles.display === 'block') {
                if (navContainer) {
                    navContainer.style.minHeight = '0';
                }

                if (pageContainer) {
                    pageContainer.style.paddingTop = '137px';
                }
            }
        }
    }, 3000);

    // List of words to replace
    const wordsToReplace = [
        'my',
        'rehab',
        'resources',
        'form',
        'treatment',
        'customize',
        'process',
    ]; // Add all words you want to replace here

    // Create a dynamic regular expression from the list of words
    const regex = new RegExp(`\\b(${wordsToReplace.join('|')})\\b`, 'gi');

    // Function to capitalize the first letter of each word
    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    // Map through items to create modified items with updated `copy` text
    const modifiedItems = items.map((item) => {
        let modifiedCopy;

        // Check if `copy` exactly matches
        if (item.copy.toLowerCase() === 'rehab inquiry form') {
            modifiedCopy = 'Rehab Inquiry';
        } else if (item.copy.toLowerCase() === 'revisa mi seguro') {
            modifiedCopy = 'Consultar Seguro';
        } else if (item.copy.toLowerCase() === 'ver centros de tratamiento') {
            modifiedCopy = 'Ver Centros';
        } else if (item.copy.toLowerCase() === 'ver proceso de admisión') {
            modifiedCopy = 'Ver Admisiones';
        } else if (item.copy.toLowerCase() === 'resources for veterans') {
            modifiedCopy = 'For Veterans';
        } else {
            // Otherwise, replace all occurrences of the words in the list with an empty string
            modifiedCopy = item.copy.replace(regex, '');
        }

        // Capitalize the first letter of each word in `copy`
        modifiedCopy = capitalizeWords(modifiedCopy);

        return {
            ...item,
            copy: modifiedCopy,
            // Override the Icon properties
            Icon: (props) =>
                item.Icon({
                    fill: 'var(--interactive-300)', // Updated fill color
                    width: '24', // Updated width
                    height: '24', // Updated height
                    ...props, // Ensure all other props are still passed
                }),
        };
    });

    return (
        <div className="next-steps-nav__mobile">
            {Array.isArray(modifiedItems) &&
                modifiedItems.map((option, index) => {
                    const {onClick, href, Icon, copy} = option;
                    if (href === pathname) return null;
                    return (
                        <div key={index} className="next-steps-nav__mobile--option">
                            {onClick && (
                                <div onClick={onClick} className="next-steps__item">
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        dangerouslySetInnerHTML={{
                                            __html: copy,
                                        }}
                                    />
                                </div>
                            )}
                            {href && (
                                <Link
                                    href={href}
                                    title={copy}
                                    style={{
                                        color: 'var(--interactive-300)',
                                        textDecoration: 'none',
                                        fontSize: '12px',
                                        lineHeight: '20px',
                                        display: 'flex',
                                    }}>
                                    <span className="next-steps-nav__mobile--icon">
                                        <Icon />
                                    </span>{' '}
                                    <span
                                        className="next-steps-nav__mobile--copy"
                                        dangerouslySetInnerHTML={{__html: copy}}
                                    />
                                </Link>
                            )}
                        </div>
                    );
                })}
            <style jsx>
                {`
                    .next-steps-nav__mobile {
                        background: var(--interactive-100);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 2px;
                        border-bottom: 1px solid var(--interactive-300);
                    }
                    .next-steps-nav__mobile--option {
                        text-align: center;
                        padding: 8px;
                        cursor: pointer;
                        height: 40px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        grid-gap: 4px;
                        border-top: 1px solid var(--interactive-300);
                    }
                    .next-steps-nav__mobile--icon {
                        display: flex;
                        flex-direction: row;
                        width: 24px;
                        height: 24px;
                        color: var(--interactive-300);
                    }
                    .next-steps-nav__mobile--option > a,
                    .next-steps-nav__mobile--option > .next-steps__item {
                        color: var(--interactive-300);
                        text-decoration: none;
                        max-width: 100%;
                    }
                    .next-steps__item,
                    .next-steps-nav__mobile--copy {
                        align-items: center;
                        font-size: 10px;
                        display: flex;
                        padding-left: 4px;
                    }
                    .next-steps-nav__mobile--option:nth-child(2) {
                        border-left: 1px solid var(--interactive-300);
                        border-right: 1px solid var(--interactive-300);
                    }
                    .next-steps-nav__container {
                        min-height: 0 !important;
                    }
                `}
            </style>
        </div>
    );
};
export default NextStepsNavMobile;
