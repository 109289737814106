import React, {Suspense, createElement, useContext} from 'react';
import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import {getSegmentNumber, useMentalHealthVob} from '../../lib/utils';
import styled from 'styled-components';

import Portals from 'aac-components/components/ShortcodePortals/ShortcodePortals';
import {Quote} from 'aac-components/components/Quote';
import Callout from 'aac-components/components/Callout';
import {LinkBank} from 'aac-components/components/LinkBank';
import Sources from 'aac-components/components/Sources/Sources';

import SubstanceSearch from 'aac-components/components/SubstanceSearch/index';
import Accordion from 'aac-components/components/Accordion';
import SelfAssessment from 'aac-components/components/SelfAssessment';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import FAQs from 'aac-components/components/FAQs/FAQs';
import Button from 'aac-components/components/Button';
import {
    ButtonStyles,
    ExtraMargin,
    ContactFormStyles,
    LinkBankStyles,
} from './shortcodeStyleOverrides';
import AktifyShortcode from 'aac-components/components/SegmentedShortcodes/AktifyShortcode';
import CallrailLink from '../CallrailLink';
import AccordionLinks from '../AccordionLinks';
import DrawerAccordion from '../DrawerAccordion';
import VobAktifyCta from 'aac-components/components/VobAktifyCta';
import ExpandableCallout from 'aac-components/components/ExpandableCallout';
import CtaShortcode from '../CtaShortcode';
import VeteranCta from '../VeteranCta';
import ShortcodeTooltip from '../ShortcodeTooltip';
import VobShortcode from 'aac-components/components/SegmentedShortcodes/VobShortcode';
import CallFormCta from '../CallForm/CallFormCta';
import IconPhone from 'aac-components/components/Icons/Basic/IconPhone';
import LocationTextShortcode from 'aac-components/components/LocationTextShortcode';
import AppContext from '../AppContext';
import Figure from '../Figure';
import Video from 'aac-components/components/Video';
import ContactFormAbandonModal from 'aac-components/components/ContactForm/ContactFormAbandonModal';
import ContactForm from 'aac-components/components/ContactForm';

// Test
import PhoneShortcode from '../PhoneShortcode';
import StateFundedVsPrivateCta from '../StateFundedVsPrivateCta';
import {getPreFillVtx} from '../PageTemplates/InsuranceCoverage';
import Carousel from 'aac-components/components/Carousel';
import {isMappableArray} from 'aac-components/utils/helpers';
import CarouselShortcode from '../CarouselShortcode';

// Use React memo to prevent unnecessary re-rendering
// This fixes bug when user clicks on the insurance field on mobile it doesn't automatically close the dropdown
const SelfAssessmentMemo = React.memo(SelfAssessment);

const VobMemo = React.memo(VobShortcode);

// dynamic imports
const DynamicVideoPopup = dynamic(() => import('../VideoPopup'));
const DynamicSegmentCta = dynamic(() => import('../SegmentCta'));
const FinancingInquiryForm = dynamic(() =>
    import('aac-components/components/FinancingInquiryForm'),
);
const VeteranSelfAssessment = dynamic(() => import('../VeteranSelfAssessment'));

const PersonaAssessment = dynamic(() => import('../PersonaAssessment'));
const BirdeyeReview = dynamic(() => import('aac-components/components/BirdeyeReview'));

const PortalContext = React.createContext({});

const shortcodes = [
    {
        selector: 'portal_location-text',
        component: (props) => {
            const context = useContext(AppContext);
            return (
                <Suspense fallback={<div>...</div>}>
                    <LocationTextShortcode
                        location={context?.sessionStorageState?.geolocation?.location}
                        pretext={props?.pretext}
                        posttext={props?.posttext}
                        fallbacktext={props?.fallbacktext}
                    />
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_self-assessment',
        component: (props) => {
            const {asPath} = useRouter();
            const lang = asPath?.includes('/esp') ? 'es' : 'en';

            return (
                <Suspense fallback={<div>...</div>}>
                    {props?.type === 'vet' ? (
                        <VeteranSelfAssessment />
                    ) : props?.type === 'persona' ? (
                        <ExtraMargin>
                            <PersonaAssessment />
                        </ExtraMargin>
                    ) : (
                        <ExtraMargin>
                            <SelfAssessmentMemo {...props} lang={lang} />
                        </ExtraMargin>
                    )}
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_quote',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Quote {...props} text={props?.children} />
            </Suspense>
        ),
    },
    {
        selector: 'portal_callout',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Callout
                    {...props}
                    buttonText={props?.buttontext}
                    altText={props?.altText}
                    imgSrc={props.image || ''}
                    imgAlt={props.alt || ''}
                    content={props?.children}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_link_bank',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <LinkBankStyles>
                    <LinkBank
                        {...props}
                        children={[
                            <div
                                key="link-bank-key"
                                className="link-bank__container"
                                dangerouslySetInnerHTML={{__html: props.children}}
                            />,
                        ]}
                    />
                </LinkBankStyles>
            </Suspense>
        ),
    },
    {
        selector: 'portal_media',
        component: (props) => <Figure {...props} />,
    },
    {
        selector: 'portal_sources',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Sources
                    children={[
                        <div
                            key="sources-key"
                            className="sources__container"
                            dangerouslySetInnerHTML={{__html: props.children}}
                        />,
                    ]}
                />
            </Suspense>
        ),
    },
    {
        selector: 'portal_ssvob',
        component: (props) => {
            const {
                pageProps: {
                    data: {tags = [], slug = '', acf: {content_segment = ''} = {}} = {},
                } = {},
                callTrackingNumber,
            } = ({} = useContext(AppContext));
            const segment = getSegmentNumber(content_segment);
            const lang = slug === 'esp' ? 'es' : 'en';

            return (
                <VobAbandonModal>
                    <VobMemo
                        {...props}
                        segment={segment}
                        lang={lang}
                        vtxOnLoad={getPreFillVtx(slug)}
                        mentalHealthForm={useMentalHealthVob(tags)}
                        callTrackingNumber={callTrackingNumber}
                    />
                </VobAbandonModal>
            );
        },
    },
    {
        selector: 'portal_substance_search',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <SubstanceSearch />
            </Suspense>
        ),
    },
    {
        selector: 'portal_accordion',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <Accordion title={props.title}>
                    <div dangerouslySetInnerHTML={{__html: props.children}} />
                </Accordion>
            </Suspense>
        ),
    },
    {
        selector: 'portal_faqs',
        component: (props) => {
            const context = useContext(PortalContext);
            const {
                data: {
                    acf: {include_faq_schema = true, faqs: {faqs_list = []} = {}} = {},
                },
            } = ({} = context);

            return (
                <Suspense fallback={<div>...</div>}>
                    <FAQs
                        title={''}
                        faqs={faqs_list}
                        includeSchema={include_faq_schema}
                    />
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_aktify-form',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <div style={{margin: '40px 0'}}>
                    <AktifyShortcode />
                </div>
            </Suspense>
        ),
    },
    {
        selector: 'portal_button',
        component: (props) => (
            <Suspense fallback={<div>...</div>}>
                <ButtonStyles>
                    <Button
                        href={props.url}
                        theme={props?.outline ? 'invert' : 'primary'}
                        style={{
                            color: props?.outline ? 'var(--interactive)' : '#fff',
                            textDecoration: 'none',
                        }}>
                        {props.text}
                    </Button>
                </ButtonStyles>
            </Suspense>
        ),
    },
    {
        selector: 'portal_phone_link',
        component: (props) => <PhoneShortcode {...props} />,
    },
    {
        selector: 'portal_accordion-links',
        component: (props) => {
            return <AccordionLinks title={props?.title} type={props?.type} />;
        },
    },
    {
        selector: 'portal_video-popup',
        component: (props) => {
            return <DynamicVideoPopup src={props?.src} />;
        },
    },
    {
        selector: 'portal_video',
        component: (props) => {
            const {isMobile} = useContext(AppContext);
            return (
                <Suspense fallback={<div>...</div>}>
                    <div
                        style={{
                            marginBottom: '40px',
                        }}>
                        <Video {...props} height={isMobile ? '230px' : '360px'} />
                    </div>
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_insurance-addiction-search',
        component: (props) => {
            return (
                <ExtraMargin>
                    <SubstanceSearch />
                </ExtraMargin>
            );
        },
    },
    {
        selector: 'portal_schedule-call',
        component: (props) => {
            return <CallFormCta />;
        },
    },
    {
        selector: 'portal_segment-cta',
        component: (props) => {
            const context = useContext(PortalContext);
            const data = context?.data || [];
            return <DynamicSegmentCta pageData={data} type={props?.type} />;
        },
    },
    {
        selector: 'portal_drawer',
        component: (props) => {
            return (
                <DrawerAccordion title={props?.title}>{props?.children}</DrawerAccordion>
            );
        },
    },
    {
        selector: 'portal_financing-inquiry-form',
        component: () => {
            const {callTrackingNumber} = useContext(AppContext);
            return (
                <ExtraMargin>
                    <FinancingInquiryForm callTrackingNumber={callTrackingNumber} />
                </ExtraMargin>
            );
        },
    },
    {
        selector: 'portal_vob_aktify_cta',
        component: () => {
            return (
                <ExtraMargin>
                    <VobAktifyCta />
                </ExtraMargin>
            );
        },
    },
    {
        selector: 'portal_expandable-callout',
        component: (props) => {
            return <ExpandableCallout {...props} content={props?.children} />;
        },
    },
    {
        selector: 'portal_cta',
        component: (props) => {
            if (props?.type === 'salute-to-recovery' || props?.type === 'veteran') {
                return <VeteranCta />;
            }
            if (props?.type === 'state-funded-vs-private') {
                return <StateFundedVsPrivateCta />;
            } else {
                return <CtaShortcode {...props} />;
            }
        },
    },
    {
        selector: 'portal_tooltip',
        component: (props) => {
            return (
                <Suspense fallback={<span>{props?.children}</span>}>
                    <ShortcodeTooltip
                        text={props?.text}
                        link={props?.link}
                        linkText={props?.linktext}>
                        {props?.children}
                    </ShortcodeTooltip>
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_contact-form',
        component: (props) => {
            const {callTrackingNumber} = useContext(AppContext);
            return (
                <Suspense fallback={<div>...</div>}>
                    <div className="container">
                        {props?.children && (
                            <div
                                className="form__content"
                                dangerouslySetInnerHTML={{__html: props?.children}}
                            />
                        )}
                        <ContactFormStyles>
                            <ContactFormAbandonModal
                                callTrackingNumber={callTrackingNumber}>
                                <ContactForm callTrackingNumber={callTrackingNumber} />
                            </ContactFormAbandonModal>
                        </ContactFormStyles>
                    </div>
                </Suspense>
            );
        },
    },
    {
        selector: 'portal_birdeye_reviews',
        component: (props) => {
            return (
                <>
                    <BirdeyeReview
                        location={props?.location}
                        searchStr={props?.keyword}
                        showMore={props?.showmore == 'true'}
                        buttonText={props?.text || 'More Reviews'}
                        buttonLink={props?.url || '/reviews'}
                    />
                </>
            );
        },
    },
    {
        selector: 'portal_carousel',
        component: (props) => {
            return <CarouselShortcode {...props} />;
        },
    },
];

const ShortcodePortals = (props) => {
    return (
        <PortalContext.Provider value={props}>
            <ABTestStyles>
                <Portals shortcodes={shortcodes} />
            </ABTestStyles>
        </PortalContext.Provider>
    );
};

export default ShortcodePortals;

const ABTestStyles = styled.div`
    .portal_phone_link {
        border: 1px solid green;
    }
`;
