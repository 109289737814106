import React, {useContext, useEffect, useState} from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import Image from 'next/image';
import MaxWidth from '../../MaxWidth';
import {shortcodes} from '../../../lib/shortcodes';
import Breadcrumbs from 'aac-components/components/Breadcrumbs';
import {isSpanishPage} from '../../../lib/utils';
import Layout from '../../Layout';
import InNetworkBadge from 'aac-components/components/InNetworkBadge';
import VobAbandonModal from 'aac-components/components/VobApp/VobAbandonModal';
import FacilityCardSlider from '../../FacilityCardSlider';
import ContentTabs from 'aac-components/components/ContentTabs';
import FAQs from 'aac-components/components/FAQs/FAQs';
import RelatedTopics from '../../RelatedTopics';
import Byline from '../../Byline';
import IconArrowShort from 'aac-components/components/Icons/Arrow/IconArrowShort';
import VobApp from 'aac-components/components/VobApp';
import AppContext from '../../AppContext';
import ContributorCard from 'aac-components/components/ContributorCard';

import Accordion from 'aac-components/components/Accordion';
import AcfTooltip from '../../Tooltips/AcfTooltip';
import HipaaBadge from 'aac-components/components/HipaaBadge';
import TopReasons from '../../InsuranceTemplateComponents/TopReasons';

import {
    InsuranceCoverageTemplateStyles,
    FAQStyles,
    RelatedTopicsStyles,
    BreadcrumbsStyles,
    ContentTabsStyles,
    BylineStyles,
} from './InsuranceCoverageStyles.js';
import PagePills from '../../PagePills/index.js';
import {aacLogoHorizontal} from '../../../lib/globals.js';

const preFillVtxPages = [
    {
        slug: 'blue-cross-blue-shield-association',
        vtxProvider: 'VTX0453',
    },
    {
        slug: 'aetna',
        vtxProvider: 'VTX0490',
    },
    {
        slug: 'cigna-insurance',
        vtxProvider: 'VTX0127',
    },
    {
        slug: 'ambetter',
        vtxProvider: 'VTX0521',
    },
    {
        slug: 'tricare',
        vtxProvider: 'TRICARE Certified',
    },
    {
        slug: 'va-benefits',
        vtxProvider: 'VA Benefits',
    },
];
export const getPreFillVtx = (slug) => {
    return preFillVtxPages?.find((x) => x?.slug === slug)?.vtxProvider || null;
};

const InsuranceCardInNetwork = dynamic(() =>
    import('../../InsuranceTemplateComponents/InsuranceCardInNetwork'),
);

const InsuranceCoverageTemplate = (props) => {
    const data = props?.data || {};
    const title = data?.title?.rendered || '';
    const acf = data?.acf || {};
    const slug = data?.slug || '/';
    const content = data?.content?.rendered || '';
    const relatedPages = data?.related_posts || [];
    const byline = data?.byline_info || {};
    const lastUpdated = data?.modified || '';

    let {
        hero_copy = '',
        insurance_company_name = '',
        lower_section_content = '',
        ssvob_section_content = '',
        content_tabs = [],
        is_in_network = false,
        in_network_facilities = [],
        out_of_network_facilities = [],
        insurance_logo = '',
        faqs: {faqs_list: faqs = [], include_faq_schema = true} = {},
    } = acf;

    const {isMobile, callTrackingNumber} = useContext(AppContext);

    // make sure these are arrays
    in_network_facilities = Array.isArray(in_network_facilities)
        ? in_network_facilities
        : [];
    out_of_network_facilities = Array.isArray(out_of_network_facilities)
        ? out_of_network_facilities
        : [];

    return (
        <Layout>
            <InsuranceCoverageTemplateStyles>
                <section className="section__hero">
                    <Image
                        src="/static/insurance-template-hero.jpeg"
                        priority={true}
                        alt="American Addiction Centers Photo"
                        fill
                        style={{objectFit: 'cover'}}
                        sizes="100vw"
                    />
                    <MaxWidth>
                        <div className="section__hero--content">
                            <div>
                                <div className="section__hero--title">
                                    {!isMobile && (
                                        <BreadcrumbsStyles>
                                            <Breadcrumbs IconFill="#fff" />
                                        </BreadcrumbsStyles>
                                    )}

                                    <div className="hero__content--main">
                                        {isMobile && (
                                            <BreadcrumbsStyles>
                                                <Breadcrumbs IconFill="#fff" />
                                            </BreadcrumbsStyles>
                                        )}
                                        {is_in_network && (
                                            <div style={{marginBottom: '16px'}}>
                                                <InNetworkBadge
                                                    provider={insurance_company_name}
                                                    callTrackingNumber={
                                                        callTrackingNumber
                                                    }
                                                />
                                            </div>
                                        )}
                                        <h1 dangerouslySetInnerHTML={{__html: title}} />
                                        <PagePills />
                                        <BylineStyles>
                                            <Byline
                                                contributors={byline}
                                                lastModified={lastUpdated}
                                                theme="light"
                                            />
                                        </BylineStyles>
                                    </div>
                                </div>

                                <div className="hero__content">
                                    {hero_copy && (
                                        <div
                                            className="hero__content--copy"
                                            dangerouslySetInnerHTML={{
                                                __html: hero_copy,
                                            }}
                                        />
                                    )}
                                    {insurance_logo && is_in_network && (
                                        <div className="hero__content--card">
                                            <InsuranceCardInNetwork
                                                logo={insurance_logo}
                                                name={insurance_company_name}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="hero__content--vob">
                                <VobAbandonModal>
                                    <VobApp
                                        type="multi-step"
                                        vtxOnLoad={getPreFillVtx(slug)}
                                    />
                                    <AcfTooltip type="ssvob" color="#fff" />
                                    <div
                                        style={{
                                            margin: '0 auto',
                                            maxWidth: 'fit-content',
                                        }}>
                                        <HipaaBadge />
                                    </div>
                                </VobAbandonModal>
                            </div>
                        </div>
                    </MaxWidth>
                </section>
                <div>
                    <MaxWidth>
                        {content && (
                            <section className="section__content">
                                <div
                                    id="wordpress-content"
                                    dangerouslySetInnerHTML={{
                                        __html: content,
                                    }}
                                />
                            </section>
                        )}
                        {content_tabs && (
                            <section className="section__content-tabs">
                                {isMobile ? (
                                    <>
                                        {Array.isArray(content_tabs) &&
                                            content_tabs.map((tab, index) => {
                                                return (
                                                    <React.Fragment
                                                        key={`${title}-${index}`}>
                                                        <Accordion
                                                            title={tab?.title}
                                                            expand={index === 0}>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: tab?.content,
                                                                }}
                                                            />
                                                        </Accordion>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </>
                                ) : (
                                    <ContentTabsStyles>
                                        <ContentTabs items={content_tabs} />
                                    </ContentTabsStyles>
                                )}
                            </section>
                        )}
                    </MaxWidth>
                    <MaxWidth>
                        <section className="section__facilities">
                            <div className="section__facilities--image">
                                <Image
                                    src={aacLogoHorizontal}
                                    width={0}
                                    height={0}
                                    sizes="300px"
                                    style={{height: '50px', width: 'auto'}}
                                    alt="American Addiction Centers Logo"
                                />
                            </div>
                            {in_network_facilities || out_of_network_facilities ? (
                                <h2>
                                    Rehabs That May Accept{' '}
                                    {insurance_company_name ? (
                                        <>{insurance_company_name}</>
                                    ) : (
                                        'This'
                                    )}{' '}
                                    Insurance
                                </h2>
                            ) : (
                                <h2>Our Drug & Alcohol Rehab Centers</h2>
                            )}
                            <div className="section__facilities--intro">
                                American Addiction Centers (AAC) owns and operates
                                facilities at various locations nationwide to provide you
                                or your loved one addiction and mental health care.
                                <div>
                                    <Link href="/treatment-centers" legacyBehavior>
                                        <a
                                            title="View All Facilities"
                                            style={{
                                                color: 'var(--tertiary)',
                                                margin: '24px auto 0 auto',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textDecoration: 'none',
                                            }}>
                                            View All Facilities{' '}
                                            <IconArrowShort
                                                fill="var(--tertiary)"
                                                rotate="-90"
                                            />
                                        </a>
                                    </Link>
                                </div>
                            </div>
                            <div className="section__facilities--cards">
                                <FacilityCardSlider
                                    insuranceName={insurance_company_name}
                                    inNeworkIds={in_network_facilities}
                                    showViewAllButton={false}
                                />
                            </div>
                        </section>
                    </MaxWidth>
                </div>

                <section>
                    <MaxWidth>
                        {lower_section_content ? (
                            <div
                                className="section__lower-content"
                                dangerouslySetInnerHTML={{__html: lower_section_content}}
                            />
                        ) : (
                            <TopReasons />
                        )}
                    </MaxWidth>
                </section>

                <MaxWidth>
                    <section className="section__faqs">
                        {faqs && faqs.length >= 1 && (
                            <>
                                <h2>Frequently Asked Questions</h2>
                                <FAQStyles>
                                    <FAQs
                                        faqs={faqs}
                                        TitleElement="h3"
                                        title=""
                                        includeSchema={include_faq_schema}
                                    />
                                </FAQStyles>
                            </>
                        )}
                    </section>

                    <section className="section__next-steps">
                        <NextSteps slug={slug} />
                        <ContributorCard
                            contributors={byline}
                            editorialPolicyLink="/about-us/editorial-policy"
                        />{' '}
                    </section>
                    <hr />
                    <section className="section__related">
                        <RelatedTopicsStyles>
                            <RelatedTopics pages={relatedPages} />
                        </RelatedTopicsStyles>
                    </section>
                </MaxWidth>

                <style jsx>{shortcodes}</style>
            </InsuranceCoverageTemplateStyles>
        </Layout>
    );
};

export default InsuranceCoverageTemplate;

const NextSteps = ({slug}) => {
    const spanishPage = isSpanishPage(slug);
    const links = spanishPage ? dropdownLinksEsp : dropdownLinks;
    const title = spanishPage ? 'Próximos pasos' : 'Next Steps';
    return (
        <div className="next-steps">
            <div className="next-steps__title">
                <span>{title}</span>
            </div>
            <div className="next-steps__links">
                <ul>
                    {links.map((link) => {
                        const {title, href} = link;
                        return (
                            <li key={href} className="next-steps__link">
                                <Link href={href} legacyBehavior>
                                    <a title={title}>{title}</a>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <style jsx>
                {`
                    .next-steps {
                        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
                            0px 1px 2px rgba(0, 0, 0, 0.3);
                        border-radius: 5px;
                        margin-bottom: 30px;
                    }
                    .next-steps__title {
                        background: var(--primary);
                        color: #fff;
                        padding: 10px 15px;
                        border-radius: 5px 5px 0 0;
                        font-size: 20px;
                        font-weight: 600;
                        text-align: center;
                    }
                    .next-steps__links {
                        padding: 15px;
                        background: #fff;
                        border-radius: 0 0 5px 5px;
                    }
                    .next-steps__links > ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                    }

                    .next-steps__links li::before {
                        content: '•';
                        color: var(--gray-300);
                        padding-right: 8px;
                        font-size: 24px;
                    }
                    .next-steps__link {
                        margin-bottom: 15px;
                    }
                    .next-steps__link > a {
                        color: var(--tertiary);
                        text-decoration: none;
                    }
                `}
            </style>
        </div>
    );
};

const dropdownLinks = [
    {
        title: 'Payment Options',
        href: '/payment-options',
    },
    {
        title: 'Our Treatment Centers',
        href: '/treatment-centers',
    },
    {
        title: 'Top 10 Reasons to Come to AAC',
        href: '/why-aac/top-10-reasons-to-come-to-aac',
    },
    {
        title: 'Admissions',
        href: '/admissions',
    },
];

const dropdownLinksEsp = [
    {
        title: 'Opciones de pago',
        href: '/payment-options',
    },
    {
        title: 'Nuestros Centros de Tratamiento',
        href: '/treatment-centers',
    },
    {
        title: '10 razones principales para venir a AAC',
        href: '/why-aac/top-10-reasons-to-come-to-aac',
    },
    {
        title: 'Admisiones',
        href: '/admissions',
    },
];
