import React, {useContext, useEffect, useState} from 'react';
import {debounce} from 'throttle-debounce';
import Footer from '../Footer';
import Navigation from '../Navigation';
import Router, {useRouter} from 'next/router';
import NProgress from 'nprogress';
import {BREAKPOINT} from '../../lib/styles';
import NextStepsNav from '../Navigation/NextStepsNav';

import {zIndex} from 'aac-components/utils/helpers';
import ShortcodePortals from '../Portals/shortcodePortals';
import AppContext from '../AppContext';
import StickyFooter from '../StickyFooter';
import HiddenOnDesktop from 'aac-components/components/HiddenOnDesktop';
import MobileBanner from '../MobileBanner';
import GlobalSliders from '../GlobalSliders';
import GetHelpTab from '../GetHelpTab';
import GeoTargetTabWrapper from '../GeoTargetTabWrapper';
import {getSegmentNumber} from '../../lib/utils';
import {requestNotificationPermission} from '../../lib/notifications';
import {sendNotification} from '../../lib/notifications';
import {hasNotificationBeenShown} from '../../lib/notifications';

Router.onRouteChangeStart = (url) => {
    NProgress.start();
};

Router.onRouteChangeComplete = () => {
    if (typeof window !== 'undefined' && window.CallTrk) {
        window.CallTrk.swap();
    }
    NProgress.done();
};

Router.onRouteChangeError = () => NProgress.done();

const Layout = (props) => {
    const {children = '', showNextSteps = true} = props;
    const [pagePadding, setPagePadding] = useState(null);
    const [isClient, setIsClient] = useState(false);
    const [ready, setReadyState] = useState(false);
    const [isAbtastyLoaded, setIsAbtastyLoaded] = useState(false);
    const [hasPagePills, setHasPagePills] = useState(false);

    const {asPath, push} = useRouter();

    const {
        pageProps = {},
        isMobile,
        sessionStorageState: {geolocation = {}} = {},
        callTrackingNumber = {},
    } = useContext(AppContext);
    const {
        data: {
            acf: {
                content_segment = '',
                hide_global_ctas: {
                    next_steps_nav: hideNextSteps,
                    top_banner: hideTopBanner,
                    sticky_footer: hideStickyFooter,
                } = {},
            } = {},
        } = {},
    } = pageProps;
    const contentSegmentNumber = getSegmentNumber(content_segment);

    // on mount
    useEffect(() => {
        if (typeof window !== 'undefined' && window.CallTrk) {
            window.CallTrk.swap();
        }

        const pagePills = document && document?.getElementById('page-pills');
        setHasPagePills(!!pagePills);

        setIsClient(true);
    }, []);

    // padding for page
    useEffect(() => {
        const navContainer = document && document.getElementById('nav-container');
        setPagePadding(navContainer?.clientHeight);
    }, [asPath, isMobile, isClient]);

    // for portals
    useEffect(() => {
        let timeout;
        timeout = setTimeout(() => setReadyState(true), 2000);

        // clean up
        return () => {
            clearTimeout(timeout);
            setReadyState(false);
        };
    }, [asPath]);

    // mutation observer for when abtasty is loaded
    useEffect(() => {
        const observer = new MutationObserver(() => {
            const abtastyScript = document.getElementById('abtasty-script');
            if (abtastyScript) {
                setIsAbtastyLoaded(true);
            } else {
                setIsAbtastyLoaded(false);
            }
        });
        observer.observe(document, {
            childList: true,
            subtree: true,
        });
        return () => observer.disconnect();
    }, []);

    // request notification permisions
    useEffect(() => {
        const delay = setTimeout(() => {
            requestNotificationPermission();
        }, 10000);
        return () => clearTimeout(delay);
    }, []);

    // specifically for AAC2-4768 and sending push notifications conditionally
    useEffect(() => {
        const isStimulantPage = asPath?.includes('stimulant');
        const isGuidePage = asPath?.includes('rehab-guide');
        if (isStimulantPage || isGuidePage) {
            const stimulantNotification = {
                id: 'stimulant',
                title: 'Are you or a loved one struggling with addiction?',
                body: 'Take the first step and learn more about detox treatment today.',
                path: '/drug-detox',
            };
            const guideNotification = {
                id: 'rehab-guide',
                title: 'Rehab treatment for addiction is within reach.',
                body: 'Learn more about our nationwide network of rehab facilities today.',
                path: '/treatment-centers',
            };
            const onScroll = () => {
                const pageHalf = Math.round(document.body.scrollHeight / 2);
                const isPastHalfway = window?.scrollY >= pageHalf;
                if (isPastHalfway) {
                    if (isStimulantPage && !hasNotificationBeenShown('stimulant')) {
                        sendNotification(stimulantNotification);
                    }
                    if (isGuidePage && !hasNotificationBeenShown('rehab-guide')) {
                        sendNotification(guideNotification);
                    }
                }
            };
            window.addEventListener('scroll', debounce(1000, onScroll), {passive: true});
            // return a function to remove event listeners
            return () => window.removeEventListener('scroll', onScroll, {passive: true});
        }

        return;
    }, [asPath]);

    const showNextStepsNav =
        !asPath.includes('/verify-insurance') && !hideNextSteps && showNextSteps;

    return (
        <div>
            <div id="nav-container" className="nav-container">
                <div id="main-nav-container">
                    {!hideTopBanner && isMobile && (
                        <HiddenOnDesktop>
                            <MobileBanner />
                        </HiddenOnDesktop>
                    )}
                    <Navigation showNextStepsNav={showNextStepsNav} />
                </div>
                <div className="next-steps-nav__container">
                    {showNextStepsNav && <NextStepsNav />}
                </div>
            </div>
            <div className={`page-content`}>{children}</div>
            {ready && <ShortcodePortals {...pageProps} />}
            {/* <DriftChatBubble /> */}
            {!hideStickyFooter && <StickyFooter />}
            <Footer {...props} />

            <GlobalSliders />

            {!hasPagePills && geolocation?.facility ? (
                <GeoTargetTabWrapper
                    content_segment={content_segment}
                    callTrackingNumber={callTrackingNumber}
                    geolocation={geolocation}
                />
            ) : (
                <GetHelpTab />
            )}

            <style jsx>
                {`
                    .nav-container {
                        position: fixed;
                        width: 100%;
                        background-color: #fff;
                        z-index: ${zIndex('navigation')};
                    }
                    .page-content {
                        padding-top: ${pagePadding || 173}px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .page-content {
                            padding-top: ${pagePadding || 210}px;
                        }
                    }
                    .next-steps-nav__container {
                        min-height: ${showNextStepsNav ? '75px' : '0'};
                    }
                `}
            </style>
        </div>
    );
};

export default Layout;
