export const notificationPermissionGranted = () => {
    return 'Notification' in window && window?.Notification?.permission === 'granted';
};
export const serviceWorkerAvailable = () => {
    return 'serviceWorker' in navigator;
};

export const requestNotificationPermission = async () => {
    if (window?.Notification?.permission === 'default') {
        // track in heap
        window?.heap?.track('Notification Permission Requested');

        await window?.Notification?.requestPermission((permission) => {
            // track in heap
            window?.heap?.track('Notification Permission Response', {
                permission,
            });
            // if (permission === 'granted') {
            //     registerServiceWorker();
            //     // configurePushSubscription();
            // }
        });
    }
    return;
};
// export const registerServiceWorker = () => {
//     if (serviceWorkerAvailable()) {
//         navigator.serviceWorker.register('/sw.js').then(
//             function (registration) {
//                 console.log(
//                     'Service Worker registration successful with scope: ',
//                     registration.scope,
//                 );
//             },
//             function (err) {
//                 console.log('Service Worker registration failed: ', err);
//             },
//         );
//     }
// };

export const hasNotificationBeenShown = (id) => {
    const AacApp = window?.sessionStorage?.getItem?.('AacApp')
        ? JSON.parse(window?.sessionStorage?.getItem?.('AacApp'))
        : null;

    let shownNotifications = AacApp?.shownNotifications || [];
    return shownNotifications?.includes(id);
};

export const sendNotification = ({id = '', title = '', body = '', path = '/'}) => {
    if (notificationPermissionGranted()) {
        // don't show if already shown
        if (hasNotificationBeenShown(id)) return;

        // create new notification
        const notification = new Notification(title, {
            body,
            icon: '/static/aac_icon.svg',
        });

        // add click event for notification
        notification.onclick = (event) => {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            window.open(
                `https://americanaddictioncenters.org${path}?utm_source=chrome-push&utm_medium=chrome-push&utm_campaign=chrome-push`,
                '_blank',
            );
        };

        // save this to local storage so we don't show more than once
        const AacApp = window?.sessionStorage?.getItem?.('AacApp')
            ? JSON.parse(window?.sessionStorage?.getItem?.('AacApp'))
            : null;
        const shownNotifications = AacApp?.shownNotifications || [];
        const newStorage = {
            ...AacApp,
            shownNotifications: [...shownNotifications, id],
        };
        sessionStorage.setItem('AacApp', JSON.stringify(newStorage));
    }

    return;
};
